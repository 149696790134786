import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import ReactToolTip from "react-tooltip";
import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Skills.scss";
import { AppContext } from "../../App";

const Skills = () => {
  const [experience, setExperience] = useState([]);
  const [skills, setSkills] = useState([]);

  const darkMode = useContext(AppContext);

  useEffect(() => {
    const query = `*[_type == "experiences"]`;
    const skillsQuery = `*[_type == "skills"]`;
    client.fetch(query).then((res) => {
      setExperience(res);
    });

    client.fetch(skillsQuery).then((res) => {
      setSkills(res);
    });
  }, []);

  return (
    <>
      <h2 className={`head-text ${darkMode && "head-text-dark"}`}>
        Skills & Experience
      </h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills
            .sort(function (a, b) {
              var A = a.name.toUpperCase();
              var B = b.name.toUpperCase();
              return A < B ? -1 : A > B ? 1 : 0;
            })
            .map((skill) => (
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className={`app__skills-item app__flex ${
                  darkMode && "app__skills-item-dark"
                }`}
                key={skill.name}
              >
                <div
                  className="app__flex"
                  style={{ backgroundColor: skill.bgColor }}
                >
                  <img src={urlFor(skill.icon)} alt={skill.name} />
                </div>
                <p className={`p-text ${darkMode && "p-text-dark"}`}>
                  {skill.name}
                </p>
              </motion.div>
            ))}
        </motion.div>
        <motion.div className="app__skills-exp">
          {experience.map((exp) => (
            <motion.div
              className={`app__skills-exp-item ${
                darkMode && "app__skills-exp-item-dark"
              }`}
              key={exp.year}
            >
              <div className={`app__skills-exp-year ${darkMode &&'app__skills-exp-year-dark'}`}>
                <p className={`bold-text ${darkMode && "bold-text-dark"}`}>
                  {exp.year}
                </p>
              </div>
              <motion.div className="app__skills-exp-works">
                {exp.works.map((work) => (
                  <>
                    <motion.div
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={work.name}
                    >
                      <h4
                        className={`bold-text ${darkMode && "bold-text-dark"}`}
                      >
                        {work.name}
                      </h4>
                      <p className={`p-text ${darkMode && "p-text-dark"}`}>
                        {work.company}
                      </p>
                    </motion.div>
                    <ReactToolTip
                      id={work.name}
                      place="right"
                      effect="solid"
                      className={`app__skills-exp-tooltip ${darkMode && 'app__skills-exp-tooltip-dark'}`}
                    >
                      {work.desc}
                    </ReactToolTip>
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills",
  "app__whitebg"
);
