import { useContext } from "react";
import { AppContext } from "../../App";

const NavigationDots = ({ active }) => {
  const darkMode = useContext(AppContext);
  return (
    <div
      className={`app__navigation ${darkMode ? "app__navigation-dark" : ""}`}
    >
      {["home", "about", "work", "skills", "testimonials", "contact"].map(
        (item, index) => (
          <a
            href={`#${item}`}
            rel="noreferrer"
            key={item + index}
            className={`app__navigation-dot ${
              darkMode ? "app__navigation-dot-dark" : ""
            }`}
            style={
              active === item
                ? { backgroundColor: darkMode ? "#3fadfc" : "#313BAC" }
                : {}
            }
          >
            {null}
          </a>
        )
      )}
    </div>
  );
};

export default NavigationDots;
