import { NavigationDots, SocialIcons } from "../components";
import { useContext } from "react";
import { AppContext } from "../App";

const AppWrap = (Component, idName, classNames) =>
  function HOC() {
    const darkMode = useContext(AppContext)
    return (
      <div id={idName} className={`app__container ${classNames}`}>
        <SocialIcons />
        <div className={`app__wrapper app__flex ${darkMode && "app__wrapper-dark"}`}>
            <Component />

            <div className={`copyright ${darkMode && "copyright-dark"}`}>
                <p>© 2022 PRIYANSU CHOUDHURY</p>
                <p>ALL RIGHTS RESERVED</p>
            </div>
        </div>
        <NavigationDots active={idName} />
      </div>
    );
  };

export default AppWrap;
