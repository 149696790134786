import { images } from "../../constants";
import { useState,useContext } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { motion } from "framer-motion";
import { AppContext } from "../../App";

import "./Navbar.scss";

const Navbar = (props) => {
  const [toggle, setToggle] = useState(false);
  const darkMode = useContext(AppContext)
  return (
    <nav className={`app__navbar ${darkMode ? 'app__navbar-dark' : ''}`}>

      <div className="app__navbar-logo">
        <img src={images.logo} alt="logo" />
      </div>

      <ul className={`app__navbar-links ${darkMode? 'app__navbar-links-dark' : ''}`}>
        {["home", "about", "work", "skills", "testimonials", "contact"].map(
          (item) => (
            <li key={`link-${item}`} className="app__flex p-text">
              <div />
              <a href={`#${item}`}>{item}</a>
            </li>
          )
        )}
      </ul>

      {darkMode ? (
        <div className={`app__navbar-mode ${darkMode? 'app__navbar-mode-dark' : ''}`}>
          <MdLightMode onClick={() => props.setDark(false)} />
        </div>
      ) : (
        <div className={`app__navbar-mode ${darkMode? 'app__navbar-mode-dark' : ''}`}>
          <MdDarkMode onClick={() => props.setDark(true)} />
        </div>
      )}

      <div className={`app__navbar-menu ${darkMode? 'app__navbar-menu-dark' : ''}`}>
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {[
                "home",
                "about",
                "work",
                "skills",
                "testimonials",
                "contact",
              ].map((item) => (
                <li key={item}>
                  <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
