import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Testimonials.scss";
import { AppContext } from "../../App";

const Testimonials = () => {
  const [brands, setBrands] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const darkMode = useContext(AppContext);

  const handleClick = (index) => setCurrentIndex(index);

  useEffect(() => {
    const query = `*[_type == "testimonials"]`;
    const brandsQuery = `*[_type == "brands"]`;
    client.fetch(query).then((res) => {
      setTestimonials(res);
    });

    client.fetch(brandsQuery).then((res) => {
      setBrands(res);
    });
  }, []);

  return (
    <>
      {testimonials.length && (
        <>
          <div className={`app__testimonial-item app__flex ${darkMode && 'app__testimonial-item-dark'}`}>
            <img
              src={urlFor(testimonials[currentIndex].imgurl)}
              alt="testimonial"
            />
            <div className={`app__testimonial-content ${darkMode && 'app__testimonial-content-dark'}`}>
              <p className={`p-text`}>{testimonials[currentIndex].feedback}</p>
              <div>
                <h4 className={`bold-text`}>{testimonials[currentIndex].name}</h4>
                <h5 className={`bold-text`}>
                  {testimonials[currentIndex].company}
                </h5>
              </div>
            </div>
          </div>
          <div className={`app__testimonial-btns app__flex ${darkMode && 'app__testimonial-btns-dark'}`}>
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? testimonials.length - 1
                    : currentIndex - 1
                )
              }
            >
              <HiChevronLeft />
            </div>
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === testimonials.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }
            >
              <HiChevronRight />
            </div>
          </div>
        </>
      )}

      <div className={`app__testimonial-brands app__flex ${darkMode && 'app__testimonial-brands-dark'}`}>
        {brands.map((brand) => (
          <motion.div
            key={brand._id}
            whileInView={{ opacity: [0,1] }}
            transition={{ duration: 0.5, type: 'tween' }}
          >
            <img src={urlFor(brand.imgUrl)} alt={brand.name} />
          </motion.div>
        ))}

      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonials, "app__testimonial"),
  "testimonials",
  "app__primarybg"
);
