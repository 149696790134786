import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import { AppWrap, MotionWrap } from "../../wrapper";
import { images } from "../../constants";
import { urlFor, client } from "../../client";
import "./Footer.scss";
import { AppContext } from "../../App";

const Footer = () => {
  const darkMode = useContext(AppContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name,
      email,
      message,
    };

    client
      .create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <h2 className={`head-text ${darkMode && "head-text-dark"}`}>
        Take Me For A Coffee
      </h2>
      <div
        className={`app__footer-cards ${darkMode && "app__footer-cards-dark"}`}
      >
        <div
          className={`app__footer-card ${darkMode && "app__footer-card-dark"}`}
        >
          <img src={images.email} alt="Email" />
          <a
            href="mailto:priyansuc67@gmail.com"
            className={`p-text ${darkMode && "p-text-dark"}`}
          >
            priyansuc67@gmail.com
          </a>
        </div>
        <div
          className={`app__footer-card ${darkMode && "app__footer-card-dark"}`}
        >
          <img src={images.mobile} alt="Mobile" />
          <a
            href="tel:+917008339883"
            className={`p-text ${darkMode && "p-text-dark"}`}
          >
            +917008339883
          </a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <div className={`app__footer-form app__flex ${darkMode && 'app__footer-form-dark'}`}>
          <div className="app__flex">
            <input
              type="text"
              className={`p-text ${darkMode && "p-text-dark"}`}
              placeholder="Your Name"
              value={name}
              name="name"
              onChange={handleChangeInput}
            />
          </div>
          <div className="app__flex">
            <input
              type="email"
              className={`p-text ${darkMode && "p-text-dark"}`}
              placeholder="Your Email"
              value={email}
              name="email"
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <textarea
              name="message"
              className={`p-text ${darkMode && "p-text-dark"}`}
              value={message}
              placeholder="Your Message"
              onChange={handleChangeInput}
            ></textarea>
          </div>
          <button
            type="button"
            className={`p-text ${darkMode && "p-text-dark"}`}
            onClick={handleSubmit}
          >
            {loading ? "Sending" : "Send Message"}
          </button>
        </div>
      ) : (
        <div>
          <h3 className={`head-text ${darkMode && "head-text-dark"}`}>
            Thank You For Getting In Touch.
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
