import email from "../assets/email.png";
import mobile from "../assets/mobile.png";
import flutter from "../assets/flutter.png";
import javascript from "../assets/javascript.png";
import react from "../assets/react.png";

import profile from "../assets/profile.png";
import profile1 from "../assets/profile1.png";
import profile2 from "../assets/profile2.png";
import circle from "../assets/circle.svg";
import logo from "../assets/logo.png";

const Images = {
  email,
  mobile,
  flutter,
  javascript,
  react,
  profile,
  profile1,
  profile2,
  circle,
  logo,
};

export default Images;
