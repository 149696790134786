import { motion } from "framer-motion";
import { images } from "../../constants";
import "./Header.scss";
import { AppWrap, MotionWrap } from "../../wrapper";
import { useContext } from "react";
import { AppContext } from "../../App";

const scaleVariance = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};



const Header = () => {
  const darkMode = useContext(AppContext)

  return (
    <div className={`app__header app_flex ${darkMode&& 'app__header-dark'}`}>
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className={`badge-cmp ${darkMode && 'badge-cmp-dark'}`}>
            <span>👋🏻</span>
            <div style={{ marginLeft: 20 }}>
              <p className={`p-text ${darkMode && 'p-text-dark'}`}>Hello, I'm</p>
              <h1 className={`head-text ${darkMode && 'head-text-dark'}`}>Priyansu</h1>
            </div>
          </div>
          <div className={`tag-cmp app__flex ${darkMode && 'tag-cmp-dark'}`}>
            <p className={`p-text ${darkMode && 'p-text-dark'}`}>Web Developer</p>
            <p className={`p-text ${darkMode && 'p-text-dark'}`}>App Developer</p>
            <p className={`p-text ${darkMode && 'p-text-dark'}`}>Web Designer</p>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg" />
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: "easeInOut" }}
          src={images.circle}
          alt="profile_circle"
          className="overlay_circle"
        />
      </motion.div>

      <motion.div
        variant={scaleVariance}
        whileInView={scaleVariance.whileInView}
        className={`app__header-circles ${darkMode && 'app__header-circles-dark'}`}
      >
        {[images.react, images.flutter, images.javascript].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="circle" />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default AppWrap(
  
  MotionWrap(Header, 'app__header'),
  "home",
  "app__whitebg"
);

