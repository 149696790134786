import { useState, useEffect, useContext } from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";

import "./Work.scss";
import { AppContext } from "../../App";

const Work = () => {
  const [activeFilter, setActiveFilter] = useState("All");
  const [tags, setTags] = useState(["All"]);
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);

  const darkMode = useContext(AppContext); 

  useEffect(() => {
    const query = `*[_type == "works"]`;
    client.fetch(query).then((res) => {
      setWorks(res);
      setFilterWork(res);
    });
    const q = `*[_type == "tags"]`;
    client.fetch(q).then((res) => {
      setTags(res[0].tags);
    });
  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);
    setTimeout(() => {
      setAnimateCard({ y: 0, opacity: 1 });

      if (item === "All") {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };
  return (
    <>
      <h2 className={`head-text ${darkMode && 'head-text-dark'}`}>
        My Creative <span>Works</span> Section
      </h2>

      <div className="app__work-filter">
        {tags.map((item, index) => (
          <div
            className={`app__work-filter-item app__flex ${darkMode && 'app__work-filter-item-dark'} ${
              activeFilter === item ? (darkMode ? 'item-active-dark' :'item-active') : ""
            }`}
            key={index}
            onClick={() => handleWorkFilter(item)}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <div className={`app__work-item app__flex ${darkMode && 'app__work-item-dark'}`} key={index}>
            <div className="app__work-img app__flex">
              <img src={urlFor(work.imgUrl)} alt={work.title} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: "easeInOut",
                  staggerChildren: 0.5,
                }}
                className="app__work-hover app__flex"
              >
                <a href={work.projectLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{
                      duration: 0.25,
                    }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
                <a href={work.codeLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{
                      duration: 0.25,
                    }}
                    className="app__flex"
                  >
                    <AiFillGithub />
                  </motion.div>
                </a>
              </motion.div>
            </div>
            <div className="app__work-content app__flex">
              <h4 className={`bold-text ${darkMode && 'bold-text-dark'}`}>{work.title}</h4>
              <p className={`p-text ${darkMode && 'p-text-dark'}`} style={{ marginTop: 10 }}>
                {work.description}
              </p>

              <div className={`app__work-tag app__flex ${darkMode && 'app__work-tag-dark'}`}>
                <p className={`p-text ${darkMode && 'p-text-dark'}`}>{work.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Work, "app__works"),
  "work",
  "app__primarybg"
);
