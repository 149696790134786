import { About, Footer, Header, Skills, Testimonials, Work } from './container'
import { Navbar } from './components'
import "./App.scss"
import { useState, createContext } from 'react'

export const AppContext = createContext()

const App = () => {
  const [darkMode, setDarkMode] = useState(false)
  return (
    <AppContext.Provider value={darkMode}>
    <div className='app'>
      <Navbar  setDark={setDarkMode} />
      <Header />
      <About />
      <Work />
      <Skills />
      <Testimonials />
      <Footer />
    </div>
    </AppContext.Provider>
  )
}

export default App

