import { BsGithub, BsInstagram, BsLinkedin } from "react-icons/bs";
import "./SocialIcons.scss";
import { useContext } from "react";
import { AppContext } from "../../App";

const SocialIcons = () => {
  const redirect = (url) => {
    window.open(url, "_blank");
  };

  const darkMode = useContext(AppContext)

  return (
    <div className={`app__social ${darkMode && 'app__social-dark'}`}>
      <div onClick={() => redirect("https://github.com/Priyansu67")}>
        <BsGithub />
      </div>
      <div onClick={() => redirect("https://www.instagram.com/prxyxnxu/")}>
        <BsInstagram />
      </div>
      <div onClick={() => redirect("https://www.linkedin.com/in/priyansu-choudhury-9413aa139/")}>
        <BsLinkedin />
      </div>
    </div>
  );
};

export default SocialIcons;
